import Vue from 'vue'
import Progress4audioTask from './gloable-tasks/Progress4audioTask'

Vue.component('ContentManage', () => import('./ContentManage'))

// todo 下面这4个组件 外部有依赖！理论上应该彻底封装，只暴露路由
Vue.component('UploadAudioBatch', () => import('./gloable-tasks/UploadAudioBatch'))
Vue.component('Progress4audioTask', Progress4audioTask)
Vue.component('AudioInfo', () => import('./components/AudioInfo'))
Vue.component('TranslateEditor', () => import('./components/TranslateEditor'))


export default [
  {
    path: '/verify6direct',
    name: 'verify6direct',
    component: () => import('./Verify6ofDirect26')
  },
  {
    path: '/edit4audio',
    component: () => import('./AudioPage'),
    meta: { requiresAuth: true }, // 添加表示需要验证，      
  },
]