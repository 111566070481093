import axios from './newAxiosProxy';
import Vue from 'vue';
export default {
  /**
   * 根据类别统计【草稿、已提交、已发布、待处理】等不同内容的总篇数
   * @param {String} laun
   */
  getTaskCount(laun) {
    return axios(
      {
        method: 'get',
        url: '/common/content/taskCount',
        params: {
          contentType: laun
        }
      },
      true
    ).then(res => {
      const info = res || {};
      // 草稿和待处理只有篇数：
      const draft = {
        totalCount: (info.draft && info.draft.totalCount) || 0
      };
      const waitDeal = {
        totalCount: (info.waitDeal && info.waitDeal.totalCount) || 0
      };
      // 已发布和已提交 都有：
      const publish = {
        totalLevel: (info.publish && info.publish.totalLevel) || 0,
        totalCount: (info.publish && info.publish.totalCount) || 0
      };
      const submitted = {
        totalLevel: (info.hisTask && info.hisTask.totalLevel) || 0,
        totalCount: (info.hisTask && info.hisTask.totalCount) || 0
      };
      const disused = {
        totalLevel: (info.disused && info.disused.totalLevel) || 0,
        totalCount: (info.disused && info.disused.totalCount) || 0
      };
      return {
        draft,
        waitDeal,
        publish,
        submitted,
        disused
      };
    });
  },
  /**
   * 已提交列表
   * @param {{taskName,contentType,pageNo?,pageSize?}} params
   */
  getSubmitedList(params) {
    return axios(
      {
        method: 'get',
        url: '/common/content/hisTaskList',
        params
      }      
    );
  },
  getPublishList(params, errorCallBack = true) {
    return axios(
      {
        url: '/common/content/publishList',
        method: 'get',
        params
      },
      errorCallBack
    );
  }, // 查询所有已发布的内容
  getHbTaskList: params =>
    axios({
      params,
      url: '/common/content/taskList',
      method: 'get'
    }), // 待办任务列表
  /**
   * 已发布 下架
   * @param {Array<{id,contentType}>} hbList
   */
  offShelf(hbList, reMark) {
    hbList.forEach(hb => {
      hb.remark = reMark;
    });
    return axios(
      {
        method: 'put',
        url: '/common/content/downShelfBatch',
        data: hbList
      },
      false
    );
  },

  getWord(params) {
    return axios(
      {
        params,
        url: '/word/level/page',
        method: 'get'
      },
      true
    );
  },
  /**
   * 已发布 上架
   * @param {Array<{id,contentType}>} hbList
   */
  onShelf(hbList) {
    return axios(
      {
        method: 'put',
        url: '/common/content/reUpBatch',
        data: hbList
      },
      false
    );
  },
  /**
   * 放弃已领取的任务
   * @param {*} taskId
   * @param {Function|boolean} failCallBack
   */
  dropTask(taskId, failCallBack = true) {
    return axios(
      {
        method: 'put',
        url: '/common/content/unclaim',
        data: {
          taskId
        }
      },
      failCallBack
    );
  },

  receiveTask(taskId,processId,taskName) {
    return axios({
      method: 'put',
      url: '/common/content/check/claim',
      data: {
        taskId,processId,taskName
      }
    });
  },
  /**
   * 更新
   * @param {{id,contentType,taskName,tagMap?,imageTag?,audioTag?}} patchHb
   */
  updateHb(patchHb) {
    return axios(
      {
        method: 'put',
        url: '/common/content/update',
        data: patchHb
      },
      true
    );
  },

  /**
   * 删除
   * @param {{id,contentType,processId,taskId}} hb
   */
  deleteHb(hb, callBack) {
    hb.pass = -1;
    return axios(
      {
        method: 'delete',
        url: '/common/content/delete',
        data: hb
      },
      true
    ).then(() => {
      if (callBack) {
        callBack();
      }
      Vue.prototype.$Message.success('删除成功！');
      window.history.back();
    });
  },
  /**
   *
   * @param {Array<{bizId,processInstanceId,taskId,contentType}>} hblist
   * @param {string} reason
   */
  deleteHbBatch(hblist, reason) {
    const data = hblist.map(ele => {
      return {
        id: ele.bizId || 'bizId',
        processId: ele.processInstanceId || 'processInstanceId',
        taskId: ele.taskId,
        contentType: ele.contentType,
        taskName: ele.taskName,
        pass: -1,
        maxLevel: ele.maxLevel,
        minLevel: ele.minLevel,
        gameType: ele.gameType,
        remark: reason
      };
    });
    return axios(
      {
        method: 'delete',
        url: '/common/content/deleteBath',
        data
      },
      true
    );
  },

  /**
   *
   * @param {{type,pageNo,pageSize}} params
   */
  getRecycleList(params) {
    return axios(
      {
        method: 'get',
        url: '/common/content/disusedList',
        params
      }
    );
  },

  exportArticleCount(params) {
    return axios(
      {
        method: 'get',
        url: '/word/level/page2Excel',
        params,
        responseType: 'blob'
      },
      true
    ).then(res => {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', '文章统计.xls');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    });
  },
  wordDownloadExcel(params) {
    return axios({
      params: params,
      url: '/word/excel/module/download',
      method: 'get',
      responseType: 'blob'
    });
  },
  wordImportExcel(params) {
    return axios({
      data: params,
      url: '/word/importExcel',
      method: 'post'
    });
  },
  submitBatch(params) {
    return axios({
      url: '/contentExt/submitBatch',
      method: 'post',
      data: params
    }, true)
  },
  /**
   *
   * @param {{id?,levels:连载书等级,name:连载书名称,subject:,materialOrderList:{bizId:}}} params
   */
  // 添加连载书籍
  addSeriesBook(params){
    return axios({
      data: params,
      url: '/seriesMaterialManage/add',
      method: 'post'
    });
  },
  // 查询连载书籍
  querySeriesBook(params, errorCallBack = true) {
    return axios(
      {
        params,
        url:`/seriesMaterialManage/query/${params.pageSize}/${params.pageNo}`,
        method: 'get'
      },
      errorCallBack
    );
  },
  //删除连载书籍
  deleteSeriesBook(Id){
    return axios({
      data:'',
      url: `/seriesMaterialManage/delete/${Id}`,
      method: 'delete'
    });
  },
  //更新书籍连载
  updateSeriesBook(params){
    return axios({
      data:params,
      url: `/seriesMaterialManage/update`,
      method: 'put'
    });
  },
  //查询章节书
  querySeriesContent(params,pageSize,pageNo){
    return axios(
      {
        params,
        url:`/seriesMaterialManage/queryContent/${pageSize}/${pageNo}`,
        method: 'get'
      }
    );
  }
};
