import axios from './newAxiosProxy';
import store from '../store';
let userInfo;
/**
 * 用户登录、登出，修改、获取用户配置信息等相关接口！
 */
export default {
  getUserInfo() {
    return new Promise(resolve => {
      if (userInfo) {
        resolve(userInfo);
      } else {
        axios(
          {
            method: 'get',
            url: '/common/content/user/info'
          },
          true
        ).then(res => {
          // 屏蔽缓存
          const userInfo = res || {
            levelStart: 1,
            levelEnd: 2000
          };
          resolve(userInfo);
        });
      }
    });
  },
  setUserInfo(data) {
    return axios(
      {
        method: 'put',
        url: '/common/content/user/userset',
        data
      },
      true
    ).then(res => {
      // 去掉缓存
      // userInfo = Object.assign(userInfo, data)
      if (typeof this.OnUserInfoChange === 'function') {
        this.OnUserInfoChange();
      }
      return res;
    });
  },
  OnUserInfoChange: null,
  login(data, errorCallBack = true) {
    return axios(
      {
        method: 'post',
        url: '/sys/login',
        data
      },
      errorCallBack
    ).then(res => {
      store.dispatch('login', res);
    });
  },
  getEncryptedString(errorCallBack = true) {
    return axios(
      {
        method: 'get',
        url: '/sys/getEncryptedString'
      },
      errorCallBack
    );
  },
  logout() {
    // 退出登录只清理本地token，而不要真正退出，避免多账号相互影响！
    // return axios({
    //     method: 'get',
    //     url: '/sys/logout'
    // }, true).then(res=>{
    store.dispatch('logout');
    return Promise.resolve();
    // })
  },
  getImgVerify() {
    return '/material/sys/imgVerify?time=' + Date.now();
  } // 获取验证码
};
