<template>
  <div style="text-align:center;padding:8px 0">
    <div v-if="serialNo">
      当前绑定设备：{{serialNo}}
      <Button type="text" class="text-primary" @click="bindDevice">修改</Button>
      <Poptip confirm transfer title="确定删除吗？" @on-ok="unBind">
        <Button type="text" class="text-error">删除</Button>
      </Poptip>
    </div>
    <Button v-else type="text" class="text-primary" @click="bindDevice">绑定测试设备，使用真机预览</Button>
  </div>
</template>
<script>
import axios from '../api/newAxiosProxy';
export default {
  data() {
    return {};
  },
  computed: {
    serialNo: {
      get() {
        return this.$store.getters.getDeviceSerialNumber;
      },
      set(v) {
        return this.$store.commit('setDeviceSerialNumber', v);
      }
    }
  },
  methods: {
    bindDevice() {
      let bufNo = this.serialNo;
      this.$Modal.confirm({
        title: '输入绑定设备SN号',
        render: (h) => [
          h('div', '绑定之前确保设备SN号是已处于“测试组”内的设备'),
          h('Input', {
            props: {
              value: bufNo
            },
            on: {
              input: (v) => {
                bufNo = v;
              }
            }
          })
        ],
        onOk: () => {
          if (bufNo === this.serialNo||!bufNo||!bufNo.trim()) {
            return;
          }
          //
          axios(
            {
              method: 'put',
              url: `/common/content/user/userset`,
              data: {
                serialNumber: bufNo
              }
            },
            true
          ).then(() => {
            this.serialNo = bufNo;
          });
        }
      });
    },
    unBind() {
      axios(
        {
          method: 'delete',
          url: '/common/content/user/unbind'
        },
        true
      ).then(() => {
        this.serialNo = '';
      });
    }
  },
  created() {
    axios(
      {
        method: 'get',
        url: '/common/content/user/info'
      },
      true
    ).then((res) => {
      this.serialNo = res?res.serialNumber||'':'';
    });
  }
};
</script>

<style>
</style>