import Vue from 'vue'

Vue.component('ViewEditor', () => import('./view-editor/ViewEditor'))
Vue.component('ViewEditor4picture',()=>import('./view-editor/ViewEditor4picture'))
Vue.component('VerseEditor', () => import('./verse-editor/VerseEditor'))
Vue.component('IntensiveEditor',()=>import('./intensive-editor/IntensiveEditor'))


Vue.component('HbConstrastData', () => import('./base/HbConstrastData'))
Vue.component('EditStatus', () => import("./base/EditStatus"))
Vue.component('Colophon', () => import('./base/Colophon'))
Vue.component('BaseInfoPanel', () => import('./base/BaseInfoPanel'))
Vue.component('DeleteHbButton', () => import('./base/DeleteHbButton'))
Vue.component('BackgroundMusic',()=>import('./base/BackgroundMusic'))

