import Vue from 'vue'
import store from '../store'
Vue.component("TeachingItem", () => import('./TeachingItem'))
Vue.component('TeachingMaterial5lvlSelect', () => import('./TeachingMaterial5lvlSelect'))

export default [{
  path: 'teaching',
  component: () => import('./HomeTeachMaterial'),
  children: [
    {
      path: '',
      redirect: () => {
        let role = store.getters.getRoleCode
        if (role === 0) {
          return 'submitted'
        }
        if (role === 4) {
          return 'published'
        }
        return 'waitdeal'
      }
    }, {
      path: 'teachingcategories',
      component: () => import('./MaterialBookList'),
    }, {
      path: 'waitdeal',
      component: () => import('./WaitDealList'),
    }, {
      path: 'published',
      component: () => import('./PublishedList'),
    }, {
      path: 'submitted',
      component: () => import('./SubmitedList'),
    }, {
      path: 'recycle',
      component: () => import('./RecycleList'),
    }, {
      path: 'sortPublished',
      component: () => import('./PublishedSort')
    }
  ]
}]