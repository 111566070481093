import { waitUntil } from '../components/util'
import store from '../../store'
/**
 * 断点续传时 根据缓存记录 还原最后的上传现场
 * @param {{
    *  taskId,
    *  contentType,contentLevel,processId,status,
    *  progressMsgs: Array<string>
    * }} task 
    * @param {Array<File>} files 
    * @param {number} newChunkSize 
    * 
    */
export const reduceSence = (task, files, newChunkSize) => {
    // 初始化
    let fileInd = 0;
    let chunkInd = 0;
    const chunksInfoArr = []
    for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let ind = file.name.lastIndexOf('.')
        let type = file.name.substring(ind + 1)
        let name = file.name.substring(0, ind)
        chunksInfoArr[i] = {
            mergeName: `${name}-${Math.ceil(file.size / newChunkSize)}.${type}`,
            success: 0,
            fileSize: file.size
        }
    }
    //  现场还原
    if (task.progressMsgs.length) {
        let lastLog = task.progressMsgs[task.progressMsgs.length - 1]
        let popFlag = false;
        if (/^2:/.test(lastLog)) {
            lastLog = task.progressMsgs[task.progressMsgs.length - 2]
            popFlag = true
        }
        if (/^1#/.test(lastLog)) {
            // 如果最后面的日志是上传的信息，要还原上次的上传进度！
            let [chunk_size, up_info] = lastLog.replace('1#', '').split(':')
            if (newChunkSize === +chunk_size) {
                // chunkSize未变 才还原：
                let infos = up_info.split(';').filter(ele => !!ele).map(ele => ele.split('/').map(ele => +ele))
                for (let i = 0; i < infos.length; i++) {
                    chunksInfoArr[i].success = infos[i][0]
                    if (infos[i][0] === infos[i][1]) {
                        fileInd++
                    } else {
                        chunkInd = infos[i][0]
                    }
                }
            } else if (popFlag) {
                task.progressMsgs.pop()
            }
        }
    }
    // 初始化 2 ：满足条件可以不拆块的话，是否要选择不拆？
    for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let chunks = []
        let start = 0
        let end
        let name = file.name.substring(0, file.name.lastIndexOf('.'))
        while (start < file.size) {
            end = start + newChunkSize
            if (end > file.size) {
                end = file.size
            }
            chunks.push(new File([file.slice(start, end)], `${name}-${start / newChunkSize}`, { type: file.type }))
            start = end;
        }
        chunksInfoArr[i].chunks = chunks
    }
    return { fileInd, chunkInd, chunksInfoArr }
}

let uploadingAuth = false; // 有没有开启上传任务的权限 （只有一个标签页有开启任务的权限）
window.addEventListener('storage', e => {
    if (e.key === 'askToDUT' && uploadingAuth) {
        localStorage.setItem('answerNoDUT', Date.now())
    }
})
/**
 * 请求获取批量上传任务的 权限，申请成功所有任务将由这个标签页（或窗口）进行。 
 */
export const askToDUT = async (newAuth) => {
    if (newAuth) {
        uploadingAuth = false;
    }
    if (uploadingAuth) {
        return true;
    }
    if (store.getters.getRoleCode !== 5) {
        // 不是配音 没有权限
        return false;
    }
    let able = true;
    let cbfn = e => {
        if (e.key === 'answerNoDUT') {
            able = false
        }
    }
    window.addEventListener('storage', cbfn)
    localStorage.setItem('askToDUT', Date.now())
    await waitUntil(() => !able, 100).catch(() => '')
    window.removeEventListener('storage', cbfn)
    uploadingAuth = able
    return able
}