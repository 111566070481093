import axios from 'axios';
import store from '../store';
import Vue from 'vue';
const _axios = axios.create({
  baseURL: '/material',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});
_axios.interceptors.request.use(req => {
  req.headers['X-Access-Token'] = store.getters.getToken;
  return req;
});
_axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const res = response.data; // 真正的请求结果 res。正常请求会是一个json，文件下载会是一个blob！！！！
      if (res.code === 0 || res.code === 200) {
        return res.data || res.result;
      } else if (res.code === undefined) {
        return res;
      } else {
        // 存在code，且不为0才报错
        return Promise.reject(res.msg || res.message || '请求错误！');
      }
    } else {
      console.log(response, '111');
      return Promise.reject(response.message || response);
    }
  },
  err => {
    if (err.response) {
      err = err.response;
      if (err.data) {
        err = err.data;
      }
    }
    return Promise.reject(err.message || err.msg || err);
  }
);

/**
 *
 * @param {{method:'get'|'post'|'put'|'delete', url, data?, params?}} data
 * @param {Boolean|Function} handleError  是否弹出错误提示。 true，仅仅弹出错误提示,不会抛出错误； function，弹出提示然后调用回调函数,不会抛出错误； false（默认），会将错误抛出。
 */


// export default function proxy(data, handleError = false) {
//   return new Promise((resolve, reject) => {
//     _axios(data)
//       .then(res => {
//         resolve(res);
//       })
//       .catch(err => {
//         if (handleError) {
//           Vue.prototype.$Message.warning('[后台提示]' + (typeof err === 'string' ? err : JSON.stringify(err)));
//           if (typeof handleError === 'function') {
//             handleError();
//           }
//         } else {
//           reject(err);
//         }
//       });
//   });
// }


export default new Proxy(_axios, {
  apply: function (fn, _this, args) {
    let res = fn.call(_this, ...args)
    if (res instanceof Promise) {
      res.catch(err => {
        let handleError = args[1]
        if (handleError) {
          Vue.prototype.$Message.warning('[后台提示]' + (typeof err === 'string' ? err : JSON.stringify(err)));
          if (typeof handleError === 'function') {
            handleError();
          }
        }
      })
    }
    return res;
  }
})
