export let listRouts = [
    {
        path: 'engArticleList',
        name: 'engArticleList',
        component: () => import('./EngArticleList')
    },
]

export let editRoutes = [
    {
        path: '/engArticle/:id',
        name: 'engArticle',
        component: () => import('./EngArticle'),
        meta: { requiresAuth: true } // 添加表示需要验证
    },
    {
        path:'/engArticleInfo/:id',
        name:'engArticleInfo',
        component:()=>import('./EngArticleInfo')
    }
]