<template>
  <div style="text-align:right" v-if="show">
    <Button class="right-fix" style="height:8em" @click="showInfo=true" >
      上
      <br />传
      <br />进
      <br />度
    </Button>
    <Drawer v-model="showInfo" title="配音提交任务进度表" width="900">
      <div style="text-align:right;">
        <Button @click="delSuccessTask()">清理已完成任务</Button>
      </div>
      <Table :columns="columns" :data="taskList">
        <template slot="no" slot-scope="{index}">{{index+1}}</template>
        <template slot="contentType" slot-scope="{row}">{{row.contentType|typePipe}}</template>
        <template slot="titles" slot-scope="{row}">
          <div v-for="(article,i) in row.articleInfos" :key="i">{{article.title}}</div>
        </template>

        <template slot="progress" slot-scope="{index}">
          <div v-if="taskList[index].status===0">待处理</div>
          <div
            v-if="taskList[index].status===1"
            class="text-primary"
          >{{taskList[index].progressMsgs|progress(1)}}</div>
          <div v-if="taskList[index].status===2" class="text-success">已完成</div>
          <div
            v-if="taskList[index].status===9"
            class="text-error"
          >已失败：{{taskList[index].progressMsgs|progress(9)}}</div>

          <div v-if="taskList[index].status===3" class="text-warning">
            请求合并文件时刷新了页面，导致无法获取任务状态，请去系统查看最终状态！
            <br />
            合并请求时间：{{taskList[index].progressMsgs|progress(3)}}
          </div>
        </template>
        <template slot="oper" slot-scope="{index}">
          <div v-if="taskList[index].status===2">
            <Icon style="font-size:20px;cursor:pointer" title="删除任务记录" type="ios-close" @click="delSuccessTask(taskList[index])"/> 
          </div>
          <div v-if="taskList[index].status===9" >
            <Icon style="font-size:20px;cursor:pointer" title="重新开启任务" type="ios-refresh" @click="restart(taskList[index])"/> 
            <Poptip confirm @on-ok="delFailTask(taskList[index])" title="确定删除吗？">
              <Icon style="font-size:20px;cursor:pointer" class="text-error" title="删除任务记录" type="ios-close"/> 
            </Poptip>
          </div>
        </template>
      </Table>
    </Drawer>
  </div>
  <div v-else></div>
</template>

<script>
import { TaskList,deleteSuccessTasks,restartTask,deleteFailedTask } from "./discontinuedUploadService";
import {  askToDUT } from "./service";
export default {
  filters: {
    progress(strs, status) {
      if ("0" === strs[strs.length - 1]) {
        return "正在领取任务...";
      }
      if (status === 1) {
        if (strs.length === 1 && /^1#/.test(strs[0])) {
          let str = strs[0].replace("1#", "").split(":")[1];
          let items = str.split(";").filter(ele => ele);
          items = items.map(ele => ele.split("/").map(ele => +ele));
          let all = 0;
          let finish = 0;
          items.forEach(ns => {
            all += ns[1];
            finish += ns[0];
          });
          return "正在上传：" + Math.round((finish * 100) / all) + "%";
        }
        if (strs.length === 2 && /^2:/.test(strs[1])) {
          return "上传完成，正在请求合并...";
        }
      }
      if (status === 3) {
        let ind = strs.findIndex(ele => /^2:/.test(ele));
        if (ind >= 0) {
          let date = new Date(+strs[ind].replace("2:", ""));
          return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1 + "").padStart(2, "0") +
            "-" +
            (date.getDate() + "").padStart(2, "0") +
            "  " +
            (date.getHours() + "").padStart(2, "0") +
            ":" +
            (date.getMinutes() + "").padStart(2, "0") +
            ":" +
            (date.getSeconds() + "").padStart(2, "0")
          );
        }
      }
      if (status === 9) {
        let ind = strs.findIndex(ele => /^10:/.test(ele));
        if (ind >= 0) {
          return strs[ind].replace(/^10:[12]:/, "");
        }
        if (/^11:/.test(strs[strs.length - 1])) {
          return strs[strs.length - 1].replace(/^11:/,'');
        }
      }
      return "消息未定义";
    }
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          slot: "no",
          width: 63
        },
        {
          title: "等级",
          key: "contentLevel",
          width: 64
        },
        {
          title: "类别",
          slot: "contentType",
          width: 95
        },
        // {
        //   title: "taskId",
        //   key: "taskId",
        //   width: 298
        // },
        {
          title: "任务标题",
          slot: "titles",
          width: 270
        },
        {
          title: "当前进度",
          slot: "progress"
        },{
          title:'操作',
          slot:'oper',
          width:80
        }
      ],
      taskList: TaskList,
      isHandling: false,
      showInfo: false,
      show: true
    };
  },
  methods: {
    delSuccessTask(task){     
      deleteSuccessTasks(task)         
    },
    delFailTask(task){
      deleteFailedTask(task)
    },
    restart(task){      
      restartTask(task)
    }
  },
  mounted() {
    askToDUT(true).then(res => {
      this.show = res;
    });

  },
  watch: {}
};
</script>

<style scoped>
.right-fix {
  float: right;
  position: fixed;
  top: 100px;
  right: 0;
}
</style>
