import '../assets/styles/common.less';
import NavHeader from './NavHeader'
import Vue from 'vue';
Vue.component('TextArea', () => import('./TextArea'));
Vue.component('FirstCategory', () => import('./FirstCategory'));
Vue.component('Category', () => import('./Category'));
Vue.component('AgeSelect', () => import('./AgeSelect.vue'));
Vue.component('EditorSider', () => import('./EditorSider'));
Vue.component('NavHeader', NavHeader);
Vue.component('ProcessSteps', () => import('./ProcessSteps'));
Vue.component('TagsInput', () => import('./TagsInput'));
Vue.component('VoiceSex', () => import('./VoiceSex'));
Vue.component('HbPreviewDrawer', () => import('./HbPreviewDrawer'));
Vue.component('EditorFrame', () => import('./EditorFrame'))
Vue.component('LevelRange', () => import('./LevelRange'))
Vue.component('ReceiveStatusSelect', () => import('./ReceiveStatusSelect'))
Vue.component('ButtonsForWaitdealList', () => import('./ButtonsForWaitdealList'))
Vue.component('RewriteEntry', () => import('./RewriteEntry'))
