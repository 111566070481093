export let longSentenceEditRoutes = [
    {
        path: '/lsverify6',
        component: () => import('./LSVerify6Page')
    },
    {
        path: '/edit4lsEditor',
        component: () => import('./LSEditorPage'),
        meta: { requiresAuth: true } // 添加表示需要验证
    },
]

export let logSentenceListRoutes = [
    {
        path: 'longSentence',
        component: () => import('./LongSentenceList')
    }
]