<template>
  <div v-show="!$route.matched.some(ele=>ele.meta.hiddeHeader)">
    <header class="head">
      <div class="logo fl">
        <div class="logo-img"></div>
        <div class="logo-title">蓝色冰川内容管理系统</div>
      </div>
      <div class="opra fr">
        <router-link class="r-item text-primary" to="/configPage" v-if="showConfigLink">阅读模式</router-link>
        <span class="r-item">{{ realName }}</span>
        <span class="r-item link" @click="toLogout">注销</span>
        <Icon class="r-item link icon" type="ios-cog" @click="openSetUserForm" />
      </div>
      <div
        class="tabs-wrapper"
        v-if="$route.matched.some(ele=>ele.meta.showLauns)&&configs.length >= 2"
      >
        <div
          v-for="typObj in configs"
          :key="typObj.typeWord"
          class="tab"
          :class="{ active: laun === typObj.typeWord }"
          @click="changeLaun(typObj.typeWord)"
        >{{ typObj.typeName }}</div>
      </div>

      <slot></slot>
      <Drawer title="用户设置" :mask-closable="false" v-model="showSet">
        <div>当前字库默认等级：</div>
        <div class="flex inputs">
          <InputNumber v-model="lvlRange.levelStart" :min="0" :max="lvlRange.levelEnd===null?2000:lvlRange.levelEnd" :active-change="false" />
          <span>-</span>
          <InputNumber
            :min="lvlRange.levelStart"
            :active-change="false"
            :max="2000"
            v-model="lvlRange.levelEnd"
          />
        </div>

        <div class="flex btns">
          <Button @click="showSet = false">取消</Button>
          <Button type="primary" @click="saveUserInfo">保存</Button>
        </div>
      </Drawer>
    </header>
    <div class="device-push">
      <DevicePush v-if="[6,4].includes(roleCode)" />
    </div>
  </div>
</template>

<script>
import userService from "../api/userService";

import DevicePush from "./DevicePush";
export default {
  components: { DevicePush },
  data() {
    return {
      lvlRange: {
        levelStart: 0,
        levelEnd: 2000,
      },
      showSet: false,
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    showConfigLink() {
      return this.$store.getters.getRoleCode === 6;
    },
    configs() {
      return this.$store.getters.getAllLauns;
    },
    laun() {
      return this.$store.getters.getLaun;
    },
    realName(){
      return this.$store.state.userInfo?.realname
    }
  },
  methods: {
    changeLaun(laun){
      this.$store.commit('setLaun', laun)
    },
    saveUserInfo() {
      userService.setUserInfo(this.lvlRange).then(() => {
        this.$Message.success("设置成功");
      });
      this.showSet = false;
    },
    openSetUserForm() {
      userService.getUserInfo().then((res) => {
        this.lvlRange = res;
        this.showSet = true;
      });
    },
    async toLogout() {
      const taskId = this.$route.query.taskId;
      if (taskId) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      await userService.logout();
      this.$Message.success("退出登录成功！");
      this.$router.replace("/login");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.device-push {
  height: 48px;
  background-color: #f2f2f2;
}
.head {
  height: 30px;
  line-height: 30px;
  background: #fff;
}
.r-item {
  margin-right: 1.5em;
}
.logo {
  padding-left: 1em;
  display: flex;
  text-align: left;
  .logo-img {
    width: 2.25em;
    height: 2em;
    background-image: url("../assets/logo.jpg");
    background-size: 100% 100%;
    margin-right: 1em;
  }
}
.opra {
  text-align: right;
}
.logo,
.opra {
  width: 36em;
  min-height: 1em;
}
.link {
  color: rgb(3, 157, 18);
  cursor: pointer;
}
.icon {
  color: black;
  font-size: 18px;
}
.inputs {
  padding-top: 0.5em;
  > * {
    margin-right: 0.5em;
  }
}
.btns {
  padding-top: 1.5em;
  > * {
    margin-right: 1em;
  }
}
</style>
