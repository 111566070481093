import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'
import audioRoutes from '../page-audio'
import {gameListEntryRoutes,gameEditRoutes} from '../page-games'
import teachingRoutes from '../page-teaching'
import readmodeRoutes from '../page-readmode'
import { listRouts as engArticleListRoutes, editRoutes as engArticleEditRoutes } from '../page-engarticle'
import { longSentenceEditRoutes, logSentenceListRoutes } from '../page-longsentence'
import coursewareRoutes from '../page-courseware'
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../page-login/login'),
      meta: {
        hiddeHeader: true
      }
    },
    {
      path: '/HomeListPage',
      meta: { showLauns: true },
      component: () => import('../page-list/HomeListPage'),
      children: [
        {
          path: '',
          redirect: 'article'
        },
        {
          path: 'article',
          name: 'article',
          component: () => import('../page-list/HomeArticle')
        },
        {
          path: 'changeLevel',
          name: 'changeLevel',
          component: () => import('../page-changeLevel/ResetLevel')
        },
        ...gameListEntryRoutes,
        ...teachingRoutes,
        ...engArticleListRoutes,
        ...coursewareRoutes,
        ...logSentenceListRoutes
      ]
    },
    ...readmodeRoutes,
    ...audioRoutes,
    ...engArticleEditRoutes,
    ...longSentenceEditRoutes,
    ...gameEditRoutes,
    {
      path: '/edit4translate',
      name: 'edit4translate',
      component: () => import('@/views/TranslatePage')
    },
    {
      path: '/verify123',
      name: 'verify123',
      component: () => import('@/views/Verify123')
    },
    {
      path: '/verify6',
      redirect: to => {
        if (/private_six/.test(store.getters.getRoles)) {
          return '/verify6direct'
        } else {
          return '/verify6normal'
        }
      }
    },
    {
      path: '/verify6normal',
      component: () => import('@/views/Verify6')
    },

    {
      path: '/imgPage',
      name: 'imgPage',
      component: () => import('@/views/ImgPage'),
      meta: { requiresAuth: true } // 添加表示需要验证
    },
    {
      path: '/edit4Editor/:contentType',
      name: 'edit4Editor',
      component: () => import('@/views/EditorPage'),
      meta: { requiresAuth: true } // 添加表示需要验证
    },


  ]
});
