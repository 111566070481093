<template>
  <div id="app">
    <NavHeader />
    <router-view />
    <Progress4audioTask v-if="$route.name !== 'login'" />
    <div class="mask" v-if="showPreview" @click.self="showPreview = false">
      <MobileHbPreview class="my-prevw" :hb="hb" :hasSider="true" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: "App",
  data() {
    return {
      showPreview: false,
      hb: null,
    };
  },
  components: {},
  beforeMount(){
    Vue.prototype.$mobilePreview=(hb)=>{      
      this.hb=hb
      this.showPreview=true
    }
  }
};
</script>
<style scoped>
.my-prevw {
  margin: 60px auto 0;
  /* margin: calc(50vh - 330px) auto 0; */
}
</style>
<style>
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ivu-col-span-12 {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}
</style>