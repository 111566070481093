import { waitUntil } from '../components/util'
import homeService from "../../api/homePageService";
// todo
import { uploadChunks1by1, mergeChunks } from './upload1by1service'
import { reduceSence, askToDUT } from './service'


const STORE_NM = 'OnlyTheStore'
window.indexedDB.deleteDatabase('discontinueUpdateDB')
/**
 * @type  {IDBDatabase}
 */
let db;
(function () {
    const req = window.indexedDB.open('dcUpdateDB')
    req.onsuccess = () => {
        db = req.result
        dbService._getAllSorted().then(list => {
            TaskList.push(...list)
            uploadBatch()
        })
    }
    req.onerror = () => {
        window.alert('断点续传需要开启indexDB:' + req.error.name)
    }
    req.onupgradeneeded = () => {
        let buf = req.result
        if (!buf.objectStoreNames.contains(STORE_NM)) {
            const objectStore = buf.createObjectStore(STORE_NM, { keyPath: 'processId' })
            objectStore.createIndex('compux', ['contentType', 'contentLevel'])
            objectStore.createIndex('status', 'status')
            buf.createObjectStore('files')
        }
    }
})()

const dbService = {
    _modifyTask: function (task) {
        return new Promise(resolve => {
            const objectStore = db.transaction(STORE_NM, 'readwrite').objectStore(STORE_NM)
            objectStore.put(task).onsuccess = () => {
                resolve()
            }
        })
    },
    _getFiles: function (processId) {
        return new Promise(resolve => {
            const objectStore = db.transaction('files').objectStore('files')
            objectStore.get(processId).onsuccess = e => {
                resolve(e.target.result)
            }
        })
    },
    delSuccessTask: function (processId) {
        return new Promise((resolve, reject) => {
            const objectStore = db.transaction(STORE_NM, 'readwrite').objectStore(STORE_NM)
            if (processId) {
                objectStore.get(processId).onsuccess = e => {
                    const task = e.target.result
                    if (task.status == 2) {
                        objectStore.delete(processId).onsuccess = () => {
                            resolve([processId])
                        }
                    } else {
                        reject('要删除的任务状态不是已完成！')
                    }
                }
            } else {
                // 删除所有已完成的
                const dbIndex = objectStore.index('status')
                const range = IDBKeyRange.only(2)
                let res = []
                dbIndex.openKeyCursor(range).onsuccess = e => {
                    const cursor = e.target.result
                    if (cursor) {
                        objectStore.delete(cursor.primaryKey).onsuccess = () => {
                            res.push(cursor.primaryKey)
                            cursor.continue()
                        }
                    } else {
                        resolve(res)
                    }
                }
            }
        })
    },
    delFailedTask: function (processId) {
        return new Promise((resolve, reject) => {
            const trans = db.transaction([STORE_NM, 'files'], 'readwrite')
            const objectStore = trans.objectStore(STORE_NM)
            const filesStore = trans.objectStore('files')
            objectStore.get(processId).onsuccess = e => {
                const task = e.target.result
                if (task.status == 9) {
                    objectStore.delete(processId).onsuccess = () => {
                        filesStore.delete(processId).onsuccess = () => {
                            resolve([processId])
                        }
                    }
                } else {
                    reject('要删除的任务状态不是已失败！')
                }
            }
        })
    },
    _addTasks: function (tasks) {
        return new Promise(resolve => {
            waitUntil(() => !!db)
            const trans = db.transaction([STORE_NM, 'files'], 'readwrite')
            const objectStore = trans.objectStore(STORE_NM)
            const filesStore = trans.objectStore('files')
            let count = 0
            tasks.forEach(task => {
                task.status = 0
                task.progressMsgs = []
                let files = task.articleInfos.map(ele => ele.file)
                task.articleInfos = task.articleInfos.map(ele => ({ fileName: ele.fileName, title: ele.title }))
                objectStore.put(task).onsuccess = () => {
                    filesStore.put(files, task.processId).onsuccess = () => {
                        if (++count >= tasks.length) {
                            resolve()
                        }
                    }
                }
            });
        })

    },
    _getAllSorted: function () {
        return new Promise(resolve => {
            const objectStore = db.transaction(STORE_NM).objectStore(STORE_NM)
            const dbIndex = objectStore.index('status')
            let arr1 = [], arr0 = [], arr9 = [], arr3 = [], arr2 = []
            dbIndex.openCursor().onsuccess = e => {
                let cursor = e.target.result
                if (cursor) {
                    switch (cursor.key) {
                        case 0: arr0.push(cursor.value); break;
                        case 1: arr1.push(cursor.value); break;
                        case 2: arr2.push(cursor.value); break;
                        case 3: arr3.push(cursor.value); break;
                        case 9: arr9.push(cursor.value); break;
                    }

                    cursor.continue()
                } else {
                    resolve([...arr1, ...arr0, ...arr9, ...arr3, ...arr2])

                }
            }
        })
    },
    getIdsWillHandle() {
        return new Promise(resolve => {
            // 状态为0和1的将会处理
            const objectStore = db.transaction(STORE_NM).objectStore(STORE_NM)
            const dbIndex = objectStore.index('status')
            const range = IDBKeyRange.bound(0, 1)
            let res = []
            dbIndex.openKeyCursor(range).onsuccess = e => {
                let cursor = e.target.result
                if (cursor) {
                    res.push(cursor.primaryKey)
                    cursor.continue()
                } else {
                    resolve(res)
                }

            }
        })
    },
    _completeTask: function (task) {
        return new Promise(resolve => {
            const trans = db.transaction([STORE_NM, 'files'], 'readwrite')
            const objectStore = trans.objectStore(STORE_NM)
            const fileStore = trans.objectStore('files')
            objectStore.put(task).onsuccess = () => {
                fileStore.delete(task.processId).onsuccess = () => {
                    resolve()
                }
            }
        })
    }
}

/** 
 * 状态规范说明：
 * status 表示任务的总体状态：
 *  0 待处理，
 * 1 处理中，
 * 2 已完成，
 * 3 正在请求合并时异常刷新
 * 9 失败终止
 * （可根据 progressMsgs 重启-断点续传）  =====排序应该是 1,0,9,2
 * 
 * progressMsgs 处理任务时的所有状态记录
 * '0'  正在领取任务  （注意：每次都要领取！！！）（此记录，可不要，目前已不存在）
 * 
 * '1#1024:158/158;29/200;0/325'  领取成功，正在上传，#后面是分片大小，:后面表示各文件的上传完成的进度(按片数算)  ----不断覆盖 
 * '2:2350534266'   上传成功，正在请求合并  （不可覆盖1,1中有重要参数） :后面是发起请求的时间
 * '3'   处理完成，上面的status应该变为2 （覆盖前面所有的记录）
 * 
 * '10:合并失败了'  失败了，正在放弃任务 上面的status应该变为9。：后面是失败原因
 * '11'  失败，且放弃任务成功  
 * '19'  失败，且放弃任务失败  
 */
export const TaskList = [];
export const deleteSuccessTasks = (task) => {
    dbService.delSuccessTask(task && task.processId).then(ids => {
        ids.forEach(id => {
            TaskList.splice(TaskList.findIndex(ele => ele.processId === id), 1)
        })
    })
}
export const deleteFailedTask = async task => {
    if (!task) {
        return;
    }
    let ids = await dbService.delFailedTask(task.processId)
    ids.forEach(id => {
        TaskList.splice(TaskList.findIndex(ele => ele.processId === id), 1)
    })
}
export const restartTask = async (task) => {
    if (task.status != 9) {
        console.error('不是已失败的任务：', task)
        return;
    }
    task.status = 0
    let [log1, log2] = task.progressMsgs
    if (/^1#/.test(log1)) {
        task.progressMsgs = [log1]
    }
    if (/^2:/.test(log2)) {
        // 合并了，这时一定是合并错误（对于状态为3的，暂时还没有额外处理）
        task.progressMsgs = []
    }
    await dbService._modifyTask(task)
    uploadBatch()
}

/** 
 * @param {Array<{taskId,articleInfos:Array<{file:File,title,fileName}>,contentType,contentLevel,processId}>} tasks 
 */
export const addUploadTasks = async function (tasks) {
    await dbService._addTasks(tasks)
    let ableFlag = await askToDUT()
    if (ableFlag) {
        tasks.forEach(task => {
            const ind = TaskList.findIndex(ele => task.processId === ele.processId)
            if (ind >= 0) {
                TaskList.splice(ind, 1, task)
            } else {
                TaskList.push(task)
            }
        })
        reInitTasks()
    } else {
        localStorage.setItem('addTasksToDUT', Date.now())
    }

}
export const getIdsToHandleInCache = () => {
    return dbService.getIdsWillHandle()
}
askToDUT().then(res => {
    if (res) {
        window.addEventListener('storage', e => {
            if (e.key === 'addTasksToDUT') {
                reInitTasks()
            }
        })
    }
})
function reInitTasks() {
    // 重新排序
    dbService._getAllSorted().then(list => {
        let buf = [...TaskList.filter(ele => ele.status === 1), ...list.filter(ele => ele.status !== 1)]
        TaskList.splice(0, TaskList.length, ...buf)
        uploadBatch()
    })
}

const upload1task = async (task) => {
    const chunkSize = 2 << 20;  // 1M
    let getUploadingLog = (chunksInfoArr) => {
        let str = `1#${chunkSize}:`
        for (let ele of chunksInfoArr) {
            str += `${ele.success}/${ele.chunks.length};`
        }
        return str;
    }

    let logs = task.progressMsgs
    async function failCallBack() {
        await homeService.dropTask(task.taskId, false).then(async () => {
            logs.push('11:任务放弃成功')
            await dbService._modifyTask(task)
        }).catch(async err => {
            console.log(err)
            logs.push('19:任务放弃失败')
            await dbService._modifyTask(task)
        })
    }
    // 领取任务，领取成功后，开始上传；领取失败，给出提示，并结束。
    // 上传时拆分块，所有块上传成功后，请求合并，直到成功。存在失败给出提示，放弃任务。   
    // 这个领取中的 状态如何体现？？？
    logs.push('0')
    await homeService.receiveTask(task.taskId).catch(async err => {
        console.log(err)
        task.status = 9
        logs.pop()
        logs.push('11:' + err)
        await dbService._modifyTask(task)
        return Promise.reject()
    })
    logs.pop()
    // 最终都要变成 1    
    if (task.status === 0) {
        task.status = 1;
        await dbService._modifyTask(task)
    }
    let files = await dbService._getFiles(task.processId)
    let { fileInd, chunkInd, chunksInfoArr } = reduceSence(task, files, chunkSize)
    if (fileInd >= chunksInfoArr.length) {
        // 此种情况直接转 3,无法获取上次的 merge 是否成功
        task.status = 3
        await dbService._modifyTask(task)
        return Promise.reject('upload status: 3');
    }
    for (; fileInd < chunksInfoArr.length; fileInd++) {
        const chunks = chunksInfoArr[fileInd].chunks.slice(chunkInd)
        const lastLog = logs[logs.length - 1]
        if (!/^1#/.test(lastLog)) {
            logs.push(getUploadingLog(chunksInfoArr))
            await dbService._modifyTask(task)
        }
        await uploadChunks1by1(chunks, async () => {
            chunksInfoArr[fileInd].success++
            logs.pop()
            logs.push(getUploadingLog(chunksInfoArr))
            await dbService._modifyTask(task)
        }).catch(async err => {
            console.log(err)
            logs.push(`10:1:上传失败(${err})`)
            task.status = 9
            await dbService._modifyTask(task)
            await failCallBack()
            return Promise.reject()
        })
        chunkInd = 0
    }

    logs.push('2:' + Date.now())
    await dbService._modifyTask(task)
    mergeChunks(chunksInfoArr.map(ele => ({ fileName: ele.mergeName, fileSize: ele.fileSize }))).then(async () => {
        logs.splice(0, logs.length, '3')
        task.status = 2
        await dbService._completeTask(task)
    }).catch(async err => {
        logs.push(`10:2:文件合并失败（${err}）`)
        task.status = 9
        await dbService._modifyTask(task)
        await failCallBack()
    }).finally(() => {
        taskIds.splice(taskIds.indexOf(task.taskId), 1)
        uploadBatch()
    })

}

// 启动任务：
let uploading = false; // 是否正在处理上传任务
const taskIds = []
/**
 * @param {Array<{taskId,articleInfos:Array<{file:File,title,fileName}>,contentType,contentLevel,processId,status,progressMsgs:Array}>} tasks 内部文件以 processid + 序号 命名
 */
const uploadBatch = async () => {
    if (!(await askToDUT())) {
        console.log('no authority to upload!')
        return;
    }
    if (uploading) {
        console.log('uploading')
        return;
    }
    // 找到第一个符合条件的任务：
    let task = TaskList.find(ele => (ele.status === 1 || ele.status === 0) && taskIds.indexOf(ele.taskId) < 0)
    if (task && taskIds.length < 5) {
        uploading = true
        taskIds.push(task.taskId)
        upload1task(task).catch(err => {
            taskIds.splice(taskIds.indexOf(task.taskId), 1)
            console.error(err)
            uploadBatch()
        })
        uploading = false;
        uploadBatch()
    }
}
