import Vue from 'vue';
import App from './App';
import router from './router';
import iView from 'view-design';
import 'view-design/dist/styles/iview.css';
import './lib/quick-layout/import.scss';
// import { CurrencyPipe, CurrencyPipeKey } from './pipes/currency.pipe';
import { DateFormatPipe, DateFormatPipeKey } from './pipes/date-format.pipe';

import store from './store';
import './common/index';

import './assets/styles/page-styles.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import LsbcDesign from 'lsbc-design';
import './views/components-for-editor';
import './preview';
import './lib/vee-validate/vee-validate';
import { UploadService } from 'lsbc-design/src/lib/service';
import axios from './api/newAxiosProxy'
import { ImgSelectCompressConfig, ImgSelectConfig } from 'lsbc-design/src/lib/globalConfig'
// 

import Print from 'vue-print-nb' 
Vue.use(Print); //注册

ImgSelectCompressConfig.mimeType = "image/jpeg"
ImgSelectCompressConfig.enable = file => (file.size > 200 * 1024)
ImgSelectConfig.accept = "image/png,image/jpeg"

Vue.mixin({
  computed: {
    currentIndex: {
      get() {
        return this.$store.getters.getCurrentIndex;
      },
      set(v) {
        this.$store.commit('setCurrentIndex', v);
      }
    }
  }
});
Vue.prototype.$eventBus = new Vue();

// 清理localStorage缓存
const flag = localStorage.getItem('change');
if (!flag) {
  localStorage.clear();
  localStorage.setItem('change', 'true');
}
localStorage.removeItem('commonData');

Vue.use(LsbcDesign);
Vue.use(iView);
Vue.use(CKEditor);
Vue.prototype.$audio = new Audio();
Vue.prototype.$uploadService = new UploadService(axios);


Vue.component('ListFrame', () => import('./page-list/list-frame/ListFrame'))

Vue.filter('stepPipe', v => {
  switch (v) {
    case 0:
      return '编辑';
    case 1:
      return '一审';
    case 2:
      return '二审';
    case 3:
      return '三审';
    case 4:
      return '配图';
    case 5:
      return '配音';
    case 6:
      return '六审';
    case 7:
      return '翻译';
    default:
      return '编辑';
  }
});
Vue.filter('typePipe', (v, type, extraStr) => {
  let res;
  switch (v) {
    case 'enVerse':
      res = '英文韵文';
      break;
    case 'enView':
      res = '英文绘本';
      break;
    case 'enSentence':
      res = '英文句子';
      break;
    case 'enWords':
      res = '英文词卡';
      break;
    case 'enTeaching':
      res = '英文教材';
      break;
    case 'enIenglish':
      res = '网络文章';
      break;
    case 'cnTeaching':
      res = "中文教材";
      break;
    case 'en':
      res = '英文全部';
      break;

    case 'cnWords':
      res = '中文词语';
      break;
    case 'cnView':
      res = '中文绘本';
      break;
    case 'cnVerse':
      res = '中文韵文';
      break;
    case 'cnIdiom':
      res = '中文成语';
      break;
    case 'enLongSentence':
      res = "长难句"
      break;
    case 'cnIdiomComb':
      res = '成语接龙';
      break;
    case 'cnFlying':
      res = '飞花令';
      break;
    case 'cnIntensive':
      res = '中文精读';
      break;
    case 'cn':
      res = '中文全部';
      break;
    case '':
      res = '全部';
      break;
    case 'lzBook':
      res = '连载书籍';
      break;
    default:
      res = type === 'simple' ? '未知' : '未知类型';
  }
  if (type === 'simple') {
    res = res.replace(/(中文|英文)/, '');
  }
  if(res=='连载书籍'){
    return res
  } else {
    return res + (extraStr || '');
  }
});

Vue.filter('fileSize', str => {
  if (typeof str !== 'number') {
    return str;
  }
  if (str < 1024) {
    return str + 'B';
  }
  str = Math.round((str * 100) / 1024) / 100;
  if (str < 1000) {
    return str + 'KB';
  }
  str = Math.round((str * 100) / 1024) / 100;
  return str + 'MB';
});

// Vue.filter(CurrencyPipeKey, CurrencyPipe);
Vue.filter(DateFormatPipeKey, DateFormatPipe);

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
});
