import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 加载本地缓存的store！
let localStr = localStorage.getItem("store")
let state = Object.assign({
	// -------登录相关：-----------
	userInfo: "", // 用户基本信息
	token: "", // 		
	roles: "", // 用户的所有角色权限信息

	// ------- 全局信息： -----------
	laun: '', // 当前使用的laun语言，对于多语言角色比较重要，值为cn 或 en
	currentIndex: 0, // hb 当前活跃的是哪一篇？韵文是哪一页？
	deviceSerialNumber: '',   // 推送到app展示的设备号

	// ------ 特殊的状态信息：-------------
	showUnitsByVolumId: '',  // 教材相关（教材的接口设计和页面交互设计的冲突问题，是否可以消除这个state？）
	audioItem: null, // 用于老的配音页面，左右标记条目 互相通信。

}, localStr ? JSON.parse(localStr) : {})

const store = new Vuex.Store({
	modules: {},
	state,
	mutations: {
		//存储token方法
		//设置token等于外部传递进来的值
		setToken(state, token) {
			state.token = token
		},
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
		},
		// 设置角色
		setRoles(state, roles) {
			// 解析用户的角色：
			let enRoles = [];
			let cnRoles = [];
			let otherRoles = [];
			for (let rl of roles) {
				if (/_en$/.test(rl)) {
					enRoles.push(rl);
				} else if (/_cn$/.test(rl)) {
					cnRoles.push(rl);
				} else {
					otherRoles.push(rl);
				}
			}
			state.roles = {
				en: enRoles.join(","),
				cn: cnRoles.join(","),
				other: otherRoles.join(",")
			};
		},
		setLaun(state, laun) {
			state.laun = laun;
		},
		setCurrentIndex(state, ind) {
			if (typeof ind === 'number') {
				state.currentIndex = ind
			} else {
				throw new Error('state of "currentIndex" must be a number!')
			}
		},
		setShowUnitsByVolumId(state, id) {
			state.showUnitsByVolumId = id;
		},
		setDeviceSerialNumber(state, deviceSerialNumber) {
			state.deviceSerialNumber = deviceSerialNumber
		},
		setAudioItem(state, audioItem) {
			state.audioItem = audioItem
		}
	},
	actions: {
		logout({ commit }) {
			commit('setToken', '')
			commit('setUserInfo', {})
			commit('setRoles', [])
			commit('setLaun', '')

			setTimeout(() => {
				localStorage.removeItem("store");
			}, 100);
		},
		login({ commit, getters }, loginResult) {
			commit("setToken", loginResult.token); //同步存储token到store中
			commit("setUserInfo", loginResult.userInfo);
			commit("setRoles", loginResult.roles || []);
			commit('setLaun', getters.getDefaultLaun)
		
			setTimeout(() => {
				localStorage.setItem("store", JSON.stringify({
					laun: state.laun,
					roles: state.roles,
					token: state.token,
					userInfo: state.userInfo,
				}));
			}, 100);
		}
	},
	getters: {
		getToken(state) {
			return state.token
		},
		getDefaultLaun(state) {
			let cnRoles = state.roles.cn || []
			return cnRoles.length ? "cn" : "en";
		},
		getLaun(state) {
			return state.laun
		},
		getRoleCode(state, getters) {
			let rolesStr = getters.getRoles
			if (!rolesStr) {
				return -1
			}
			if (/_editor_/.test(rolesStr)) {
				return 0;
			}
			if (/_check_one_/.test(rolesStr)) {
				return 1;
			}
			if (/_check_two_/.test(rolesStr)) {
				return 2;
			}
			if (/_check_three_/.test(rolesStr)) {
				return 3;
			}
			if (/_picture_/.test(rolesStr)) {
				return 4;
			}
			if (/_audio_/.test(rolesStr)) {
				return 5;
			}
			if (/_check_six_/.test(rolesStr)) {
				return 6;
			}
			if (/_translate_/.test(rolesStr)) {
				return 7;
			}
			return 9
		},
		getRoles(state) {
			return (state.roles[state.laun] || '') + state.roles.other
		},
		getAllLauns(state) {
			let res = []
			if (state.roles['cn']) {
				res.push({
					typeWord: "cn",
					typeName: "中文"
				})
			}
			if (state.roles['en']) {
				res.push({
					typeWord: "en",
					typeName: "英文"
				})
			}
			return res
		},
		getCurrentIndex(state) {
			return state.currentIndex || 0
		},
		getShowUnitsByVolumId(state) {
			return state.showUnitsByVolumId
		},
		getDeviceSerialNumber(state) {
			return state.deviceSerialNumber
		},
		getOnlyRead(state,getters) {
			return /onlyRead/.test(getters.getRoles)
		},
		getAudioItem(state) {
			return state.audioItem
		}
	}
})
export default store