/**
 *
 * @param {Function <()=>any> } expressionFn
 * @param {number} maxWait
 */
export function waitUntil(expressionFn, maxWait = 10000) {
    let maxTime = Date.now() + maxWait
    return new Promise((resolve, reject) => {
        let fn = function () {
            if (expressionFn()) {
                resolve()
            } else if (maxTime < Date.now()) {
                reject(new Error('wait until time out!'))
            } else {
                window.requestIdleCallback(fn)
            }
        }
        fn();
    })
}