import store from '../store'
export let gameListEntryRoutes = [{
    path: 'game',
    component: () => import('./HomeGame'),
    children: [
        {
            path: '',
            redirect: () => {
                return store.getters.getRoleCode === 0 ? 'submitted' : 'waitDeal'
            }
        },
        {
            path: 'waitDeal',
            component: () => import('./GameWaitdeal'),
        }, {
            path: 'submitted',
            component: () => import('./GameSubmitted'),
        }, {
            path: 'published',
            component: () => import('./GamePublished'),
        }, {
            path: 'recycle',
            component: () => import('./GameRecycle'),
        },]
}]

export let gameEditRoutes = [
    {
        path: '/gameVerify6',
        component: () => import('./GmVerify6'),
    },
]