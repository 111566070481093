import axios from '../components/axios';

/**
 * 逐个上传多个分片
 * @param {Array<Blob>} chunks 要顺序上传的分片列表，注意做好筛选
 * @param { ()=>Promise} eachSuccessCallBack
 */
export const uploadChunks1by1 = async (chunks, eachSuccessCallBack) => {
  for (const chunk of chunks) {
    const formData = new FormData();
    formData.set('file', chunk);
    await axios({
      method: 'post',
      url: '/common/content/manage/uploadAudioBatch',
      timeout: 300000,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    await eachSuccessCallBack();
  }
};

function fnfnfn(e) {
  e.returnValue = 'o/';
}
/**
 *
 * @param {Array<String>} chunkNames
 */
export const mergeChunks = async chunkNames => {
  window.addEventListener('beforeunload', fnfnfn);
  return axios({
    method: 'post',
    timeout: 300000,
    url: '/common/content/manage/merge',
    data: chunkNames
  }).finally(() => {
    window.removeEventListener('beforeunload', fnfnfn);
  });
};
