import Vue from 'vue'
Vue.component('ConfigPanel', () => import('./ConfigPanel.vue'))
export default [{
    path: '/configPage',
    component: () => import('./ConfigPage'),
    children: [
        {
            path: '',
            redirect: 'normal'
        },
        {
            path: 'normal',
            name: 'normal',
            component: () => import('./ConfigNormal')
        },
        {
            path: 'delicate',
            name: 'delicate',
            component: () => import('./IntensiveSubPage')
        }
    ]
}]