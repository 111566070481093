/**
 * validate
 */

import Vue from 'vue';
import { configure, extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import zh_CN from 'vee-validate/dist/locale/zh_CN.json';
import * as rules from 'vee-validate/dist/rules';
import './vee-validate.scss';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('required', {
  message: '{_field_}必填'
});

localize('zh_CN', zh_CN);

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);

configure({
  classes: {
    valid: 'valid',
    invalid: 'invalid'
  }
});
